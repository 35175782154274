import * as React from 'react';
import * as turf from '@turf/turf';
import { observer } from 'mobx-react-lite';
import { MapButton, Stand as MapStand } from '@simosol/stands-map';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ZoomIn from '@material-ui/icons/ZoomIn';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(8),
    backgroundColor: '#fff',
    borderRadius: theme.spacing(3),
  },
}));

type ZoomToSelectedStands = {
  stands: MapStand[];
  map?: mapboxgl.Map;
};

const ZoomToSelectedStands = observer((props: ZoomToSelectedStands) => {
  const { map, stands } = props;
  const style = useStyles();

  const handleClick = () => {
    map?.fitBounds(
      turf.bbox(turf.combine(turf.featureCollection(
        [...stands.map(s => s.geoJSON)]) as any)) as [number, number, number, number],
      { padding: 20, linear: false },
    );
  };

  return (
    <MapButton className={style.root}>
      <IconButton onClick={handleClick}>
        <ZoomIn />
      </IconButton>
    </MapButton>
  );
});

export default ZoomToSelectedStands;
