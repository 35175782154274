import * as React from 'react';
import {
  MapButton,
  Stand as MapStand,
  usePositionWatcher,
  usePositionLock,
  useOwnLocationMarker,
  OwnLocationButton,
  useBaseLayer,
  useMap,
  ZoomButtons,
  BaseLayerButton,
  useMapDefaultStyles,
  useStandClick,
  useSelectedStands,
  useMapFitBoundsAndSave,
  GpsErrorDialog,
  useOptimizedStandLayers,
} from '@simosol/stands-map';
import baseLayers from './baseLayers';
import { appModel } from '../../models/App';
import Close from '@material-ui/icons/Close';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { Theme } from '@material-ui/core';
import { Project } from '../../models/Projects';
import { Stand } from '../../models/Stands';
import ZoomToSelectedStands from './ZoomToSelectedStands';

const useStyles = makeStyles((theme: Theme) => createStyles({
  closeButton: {
    position: 'absolute',
    width: theme.spacing(6),
    height: theme.spacing(6),
    left: theme.spacing(1),
    top: theme.spacing(1),
    padding: theme.spacing(1.5),
    cursor: 'pointer',
  },
  buttonLocation: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(10),
    borderRadius: theme.spacing(3),
  },
}));

type Props = {
  mapStands: MapStand[],
  currentProject: Project,
  currentStand?: Stand,
  onChange: (stand?: MapStand) => void,
};

const ProjectStandsMap = (props: Props) => {
  const { mapStands, currentProject, onChange, currentStand } = props;

  const styles = useStyles();
  const classes = useMapDefaultStyles();

  for (const layer in baseLayers) {
    baseLayers[layer].name = baseLayers[layer].name.t();
  }

  const { map, mapContainer } = useMap(
    classes.mapContainer,
    {
      accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
      container: 'map',
      zoom: 15,
      center: [23.7278, 61.4951],
      dbName: 'mapboxIDB',
      dbStore: 'tiles',
      attributionControl: false,
    },
  );
  const { baseLayer, setBaseLayer, baseLayerChangedNum } = useBaseLayer(map, baseLayers);

  useOptimizedStandLayers(map, mapStands, baseLayerChangedNum, { zoomFilter: 15 });
  useStandClick(map, onChange, baseLayerChangedNum);
  useSelectedStands(map, currentStand ? [currentStand.id] : undefined);

  /** GPS **/
  const positionWatcher = usePositionWatcher();
  const [positionLock, setPositionLock] = usePositionLock(map, positionWatcher);
  useOwnLocationMarker(map, positionWatcher, classes.locationMarker);
  useMapFitBoundsAndSave(map, mapStands, `iptim_mobile_map_bounds_${currentProject.name}`, !positionLock);

  return (
    <>
      {mapContainer}
      <BaseLayerButton
        className={classes.buttonLayers}
        allLayers={baseLayers}
        baseLayer={baseLayer}
        setBaseLayer={setBaseLayer}
      />
      <ZoomToSelectedStands map={map} stands={mapStands}/>
      <OwnLocationButton
        className={styles.buttonLocation}
        setPositionLock={setPositionLock}
        positionWatcher={positionWatcher}
        positionLock={positionLock}
      />
      <ZoomButtons
        map={map}
        className={classes.buttonsZoom}
      />
      <GpsErrorDialog
        positionWatcher={positionWatcher}
      />
      <MapButton className={styles.closeButton}>
        <Close onClick={() => appModel.map.back()}/>
      </MapButton>
    </>
  );
};

export default ProjectStandsMap;
