import * as React from 'react';
import { appModel } from '../../models/App';
import Button from '../../uicomponents/Button';
import { taskColorFromStatus } from '../tasks/Tasks';
import { LangKey } from '../../LangKey';
import DataPreloader from '../../uicomponents/DataPreloader';
import { Task, TaskStatus } from '../../models/Tasks';
import DynamicElementProps from '../stands/DynamicElementProps';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import MapOpenButton from '../map/MapOpenButton';
import { HBox, VBox } from '../../uicomponents/Boxes';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { observer } from 'mobx-react-lite';
import Text from '../../uicomponents/Text';
import { propsDisplayFiltered } from '../../models/DataNodeUtils';

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    padding: '32px 12px',
  },
  buttonContainer: {
    maxWidth: 600,
  },
  buttonLeft: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    zIndex: 1,
    minWidth: 0,
    flexGrow: 1,
  },
  buttonCenter: {
    borderRadius: 0,
    margin: '0 -1px',
    borderRight: 'none',
    borderLeft: 'none',
    zIndex: 0,
    minWidth: 0,
    flexGrow: 1,
  },
  buttonRight: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    zIndex: 1,
    minWidth: 0,
    flexGrow: 1,
  },
  projectName: {
    paddingLeft: theme.spacing(1.5),
    paddingBottom: theme.spacing(2),
  },
}));

const TaskInfo = observer(() => {
  const bgColor = (status: TaskStatus, task: Task) =>
    task.status === status ? taskColorFromStatus(status) : undefined;

  const task = appModel.tasks.currentTask;
  const styles = useStyles();
  // just MobX subscription to task version
  if (task && task.version) {}
  return (
    <>
      <DataPreloader>
        {task && <div>
          <VBox className={styles.container}>
            <HBox gap={0} className={styles.buttonContainer}>
              <Button
                style={{
                  backgroundColor: bgColor(fixStatus(TaskStatus.assigned), task),
                }}
                className={styles.buttonLeft}
                variant={'outlined'}
                size={'large'}
                onClick={() => { task.status = fixStatus(TaskStatus.assigned); }}
              >
                {LangKey.TaskStatusAssigned}
              </Button>
              <Button
                style={{
                  backgroundColor: bgColor(fixStatus(TaskStatus.ongoing), task),
                }}
                className={styles.buttonCenter}
                variant={'outlined'}
                size={'large'}
                onClick={() => { task.status = fixStatus(TaskStatus.ongoing); }}
              >
                {LangKey.TaskStatusOnGoing}
              </Button>
              <Button
                style={{
                  backgroundColor: bgColor(fixStatus(TaskStatus.executed), task),
                }}
                className={styles.buttonRight}
                variant={'outlined'}
                size={'large'}
                onClick={() => { task.status = (fixStatus(TaskStatus.executed)); }}
              >
                {LangKey.TaskStatusExecuted}
              </Button>
            </HBox>
          </VBox>
          <Text variant={'body1'} className={styles.projectName}>
            {task.joinSecondary([task.stand.municipality, task.project.name])}
          </Text>
          <DynamicElementProps model={propsDisplayFiltered(appModel.isTapio ? task.operation.props : task.props)}/>
          {
            task.stand?.geoJSONToMap &&
              <MapOpenButton onClick={() => appModel.map.showMap(task.project.id, task.stand.id)} />
          }
        </div>
        }
      </DataPreloader>
    </>
  );
});

export default TaskInfo;

export const fixStatus = (status: TaskStatus) => {
  return !appModel.isTapio
    ? status
    : status === TaskStatus.computed
      ? TaskStatus.planned
      : status === TaskStatus.planned
        ? TaskStatus.computed
        : status;
};
